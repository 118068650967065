import * as React from "react"
import styled from 'styled-components/macro'

const Angle = styled.div`
  width: 0;
  height: 0;
  border-left: 0px solid transparent;
  border-right: calc(100vw - 20px) solid transparent;
  /*border-bottom: 40px solid #1e1c29;*/
  border-bottom: 40px solid #18182a;
  top: -40px;
  position: absolute;
  
  @media screen and (max-width: 880px) {
    border-right: 100% solid transparent;
  }
`

const TopAngle = () => {

  return (
    <Angle>
    </Angle>
  )
}

export default TopAngle