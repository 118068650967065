import * as React from "react"
import styled from 'styled-components/macro'

const Title = styled.div`
  font-weight: 500;
  font-size: 32px;
  margin-bottom: 10px;
  
  @media screen and (max-width: 1400px) {
    font-size: 28px;
  }
`

export default Title