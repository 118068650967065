import * as React from "react"
import styled from 'styled-components/macro'

const Subtitle = styled.div`
  font-weight: 300;
  font-size: 15px;
  margin-bottom: 5px;
  
  a {
    text-decoration: none;
    color: #f9f9f9;
    font-weight: 600;
    font-size: 15px;
    cursor: pointer;
    
    :hover {
      color: #d5d6e1;
    }
    
    svg {
      width: 0.8em;
      vertical-align: text-bottom;
      margin-bottom: -1px;
    }
  }
  
  @media screen and (max-width: 1400px) {
    font-size: 14px;
  }
`

export default Subtitle