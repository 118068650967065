import * as React from "react"
import styled from 'styled-components/macro'
import Title from "../../common/Title";
import Subtitle from "../../common/Subtitle";
import Link from "../../common/Link"
import Lend from '../../images/lend-page-image.png'
import Pool from '../../images/pool-page-image.png'
import TopAngle from "../../common/TopAngle"
import BottomAngle from "../../common/BottomAngle"
import CoinStack from '../../images/coin-stack.svg'
import DepositPageImage from '../../images/balances-page-faded.png'
import {useState} from "react";
import TractorAndField from "../../images/tractor-and-field.svg";
import ETHLogo from "../../images/assets/ETH-logo.svg";
import BTCLogo from "../../images/assets/bitcoin-logo.svg";
import USDCLogo from "../../images/assets/USDC-logo.svg";
import ChainlinkLogo from "../../images/assets/chainlink-logo.svg";
import {keyframes} from "styled-components";

const EarnMoreSectionWrapper = styled.div`
  width: 100%;
  color: #f9f9f9;
  padding-bottom: 100px;
  padding-top: 100px;
  position: relative;
  
  > div:nth-of-type(3) {
    max-width: 1000px;
    margin: 0 auto;
    padding: 0 50px;
  }
  
  @media screen and (max-width: 880px) {
    > div:nth-of-type(3) {
      display: flex;
      flex-direction: column-reverse;
      max-width: 480px;
      padding: 0;
    }
  }
`

const Content = styled.div`
  width: calc(70% - 75px);
  padding: 25px;
  padding-left: 0;
  margin: 0 auto;
  display: inline-block;
  vertical-align: top;
  
  @media screen and (max-width: 880px) {
    width: calc(100% - 50px);
    padding: 25px 20px;
  }
`

const ComingSoonText = styled.div`
  color: #606375;
  font-size: 15px;
  line-height: 16px;
  margin-top: 12px;
  display: inline-block;
  vertical-align: top;
`

const Graphic = styled.div`
  width: 50%;
  display: inline-block;
  vertical-align: top;
  position: relative;
  height: 180px;
  margin-right: -20%;
  transform: scaleX(-1);
  
  @media screen and (max-width: 1300px) {
    width: 40%;
    margin-top: 2.5%;
    margin-left: 0;
    height: 120px;
  }
  
  @media screen and (max-width: 1100px) {
    margin-top: 5%;
  }
  
  @media screen and (max-width: 900px) {
    margin-top: 7%;
  }
    
  @media screen and (max-width: 880px) {
    width: 100%;
    margin-left: 0;
    height: 120px;
  }
`

const TractorImage = styled.div`
  width: 100%;
  position: absolute;
  z-index: 3;
  
  img {
    width: 100%;
    height: auto;
  }
  
  @media screen and (max-width: 880px) {
    width: 74%;
    margin-left: 13%;
  }
`

const TokenAnimation = keyframes`
  from {
    bottom: 20%;
    opacity: 0;
  }

  30% {
    opacity: 1;
  }
  
  55% {
    opacity: 1;
  }
  
  76% {
    opacity: 0;
    bottom: 80%;
  }
  
  to {
    opacity: 0;
    bottom: 80%;
  }
`

const Token = styled.div`
  position: absolute;
  z-index: 2;
  width: 10%;
  transform: scaleX(-1);
  
  animation-name: ${TokenAnimation};
  animation-duration: 3.5s; 
  animation-timing-function: ease-out; 
  animation-delay: 0s;
  animation-iteration-count: infinite;
  
  :nth-of-type(2) {
    right: 35%;
  }
  
  :nth-of-type(3) {
    right: 25%;
    animation-delay: -1.4s;
  }
  
  :nth-of-type(4) {
    right: 15%;
    animation-delay: -0.4s;
  }
  
  :nth-of-type(5) {
    right: 5%;
    animation-delay: -2.1s;
  }

  img {
    width: 100%;
    height: auto;
  }
  
  @media screen and (max-width: 880px) {
    width: 8%;
    
    :nth-of-type(2) {
      right: 43%;
    }
    
    :nth-of-type(3) {
      right: 33%;
      animation-delay: -1.4s;
    }
    
    :nth-of-type(4) {
      right: 23%;
      animation-delay: -0.4s;
    }
    
    :nth-of-type(5) {
      right: 13%;
      animation-delay: -2.1s;
    }
  }
`

// markup
const EarnMoreSection = () => {
  const [clickedComingSoon, setClickedComingSoon] = useState(false)

  return (
    <EarnMoreSectionWrapper>
      <TopAngle />
      <BottomAngle />
      <div>
        <Content>
          <Title>
            Earn more on more holdings.
          </Title>
          <Subtitle>
            Don’t let assets just sit in your wallet. Dolomite supports assets not found on any other lending and borrowing DeFi protocols. Lend, borrow, and earn on a wider array of unique assets.
          </Subtitle>
          <Link text={'Start earning'} dest={'https://app.dolomite.io/'} breakWidth={410} />
        </Content>
        <Graphic>
          <TractorImage>
            <img src={TractorAndField} />
          </TractorImage>
          <Token>
            <img src={ETHLogo} />
          </Token>
          <Token>
            <img src={BTCLogo} />
          </Token>
          <Token>
            <img src={USDCLogo} />
          </Token>
          <Token>
            <img src={ChainlinkLogo} />
          </Token>
        </Graphic>
      </div>
    </EarnMoreSectionWrapper>
  )
}

export default EarnMoreSection
