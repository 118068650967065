import * as React from "react"
import styled from 'styled-components/macro'
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const LinkWrapper = styled.div`
  font-weight: 500;
  font-size: 24px;
  /*${({ breakWidth }) => breakWidth ? `max-width: ${breakWidth*0.75}px;` : ''}*/
  
  a {
    text-decoration: none;
    color: #606375;
    font-weight: 400;
    font-size: 15px;
    cursor: pointer;
    
    :hover {
      color: #d5d6e1;
    }
    
    svg {
      width: 0.7em;
      vertical-align: text-bottom;
      margin-bottom: -3px;
      transition: margin 0.2s ease-in-out;
    }
    
    :hover {
      svg {
        margin-left: 3px;
      }
    }
  }
  
  @media screen and (max-width: 1400px) {
    font-size: 22px;
    
    a {
      font-size: 14px;
    }
  }
  
  @media screen and (max-width: 880px) {
    line-height: 16px;
    margin-top: 10px;
  }
  
  @media screen and (max-width: ${({ breakWidth }) => `${breakWidth}px`}) {
    width: 75%;
  }
`

const Link = ({ dest, text, breakWidth }) => {
  return <LinkWrapper breakWidth={breakWidth}>
    <a href={dest} target={'_blank'}>{text} <ArrowForwardIcon /></a>
  </LinkWrapper>
}

export default Link